<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="claimSubmitDialog" max-width="500">
      <v-card>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark>
          Claim Submitted to HRDC
        </v-toolbar>

        <div class="pa-4">
          Please confirm that you wish to Submit Claim to HRDC
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalClaimSubmit()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="claimSubmitDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--BOC:[contents]-->
    <v-card>
      <div>
        <v-toolbar color="#063058">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              {{ model.add.name }}
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <span v-if="actionDownload">
            <v-menu
              offset-y
              :rounded="'lg'"
              transition="slide-y-transition"
              :max-height="'300'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="text-white">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(action, index) in actions"
                  :key="index"
                  dense
                  @click="downloadAttachment(action)"
                >
                  <v-list-item-title>{{ action }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </span>

          <span v-if="hrdcData.isHrdcAdminRoles && !btnDisabled">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only text-white"
                  :loading="apiSubmit.isLoading"
                  @click="claimSubmitDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-document-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <span>Submit Claim</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <div class="w-50 pa-2">
        <v-card
          class="pa-2 ma-2"
          :style="{ backgroundColor: hrdcData.HRDC_CARD_COLOR }"
          outlined
        >
          <!-- Grant Approval No. -->
          <v-col class="d-flex" cols="12" sm="12" md="12">
            <v-text-field
              dense
              filled
              disabled
              label="Grant Approval No."
              v-model="formData.grantApprovalNo"
              :error-messages="errorField.grantApprovalNo"
            >
              <template v-slot:append>
                <v-icon
                  color="primary"
                  @click="copyContent(formData.grantApprovalNo)"
                  >mdi-content-copy</v-icon
                >
              </template>
            </v-text-field>
          </v-col>

          <!-- Claim ID -->
          <v-col class="d-flex" cols="12" sm="12" md="12">
            <v-text-field
              dense
              filled
              label="Claim ID"
              v-model="formData.claimId"
              :error-messages="errorField.claimId"
            ></v-text-field>
          </v-col>

          <!-- Claim Submitted Date -->
          <v-col cols="12" sm="12" md="12">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  v-model="formData.claimSubmitDate"
                  label="Claim Submit Date (YYYY-MM-DD)"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="formData.claimSubmitDate"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-card>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_tax_summit/objects/globalData";

export default {
  mixins: [hrdcFunction],
  components: {},
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  data: () => ({
    apiGetApplicationSingleDetail: new Api(),
    apiSubmit: new Api(),
    apiDownloadAttachment: new Api(),
    // apiGetCompanyDetails: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    formData: {
      applicationNo: null,
      claimId: null,
      grantApprovalNo: null,
      manualInvoiceToHrdc: null,
      claimSubmitDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
    },
    errorField: {
      applicationNo: null,
      claimId: null,
      claimSubmitDate: null,
    },
    loadingDialog: false,
    claimSubmitDialog: false,
    editMode: false,
    btnDisabled: false,
    companyName: null,
    applicationPrice: null,
    actions: [
      "Download All",
      "Download JD14",
      "Download T3",
      "Download Agenda",
      "Download HRDC Invoice",
    ],
    actionDownload: true,
  }),
  async created() {
    this.loadingDialog = !this.loadingDialog;
    this.formData.applicationNo = this.$route.query._ausk;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Events Assignation.
    this.serviceDataAssignation(this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/claim_submit/create-claim`,
        method: "post",
      },
      apiGetApplicationSingleDetail: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-single-application`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
      apiDownloadAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/claim_submit/download-attachment`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    const currentStageId =
      this.hrdcData.stageIndex[
        this.$store.state[this.service.key].data.stageId
      ];
    this.hrdcData.rolesAssignation(this.auth.roles);
    if (!this.hrdcData.isHrdcAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    this.checkFormAlreadyFilledGte(
      currentStageId,
      this.hrdcData.stageSlug.submittedClaimToHrdc
    );

    this.checkFormNotYetFilledLesser(
      currentStageId,
      this.hrdcData.stageSlug.manualInvoiceToHrdc
    );

    // if (this.editMode) {
    this.apiGetApplicationSingleDetail.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;
        if (status) {
          // Append Grant Approval No.
          this.formData.grantApprovalNo = data.grantApprovalNo;

          // Append Manual/HRDC Invoice No..
          this.formData.manualInvoiceToHrdc = data.manualInvoiceToHrdc;

          this.companyName = data.oriCompanyName;
          this.applicationPrice = data.totalPrice;

          // Check whether training end date is pass in order to submit claim.
          const today = moment().startOf("day");
          let trainingEndDate = null;
          const trainingEndDateObj = data.ApplicationLog.find(
            (item) =>
              item.stageId ==
              this.hrdcData.stagePriority.subscriptionDateScheduled
          );
          if (trainingEndDateObj) {
            trainingEndDate = moment(
              trainingEndDateObj.endDate,
              "YYYY-MM-DD HH:mm:ssZ"
            );
          }

          if (trainingEndDate) {
            if (
              trainingEndDate.isAfter(today) ||
              trainingEndDate.isSame(today)
            ) {
              this.alertError.push(
                "Please be informed that this application is not ready to submit claim."
              );
              this.btnDisabled = true;
            }
          }

          if (this.editMode) {
            const submittedClaimStageId =
              this.hrdcData.stagePriority.submittedClaimToHrdc;
            this.formData.claimId = data.claimId;
            let submittedClaim = data.ApplicationLog.find(
              (obj) => obj.stageId == submittedClaimStageId
            );

            if (submittedClaim) {
              this.formData.claimSubmitDate = moment(
                data.ApplicationLog.find(
                  (obj) => obj.stageId == submittedClaimStageId
                ).startDate
              ).format("YYYY-MM-DD");
            }
          }
        }

        this.loadingDialog = !this.loadingDialog;
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetApplicationSingleDetail.fetch();
  },
  mounted() {},
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    //EOC
    modalClaimSubmit() {
      this.claimSubmitDialog = !this.claimSubmitDialog;
      this.submit();
    },
    copyContent(item) {
      const textarea = document.createElement("textarea");
      textarea.value = item;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");

      // Remove the temporary textarea
      document.body.removeChild(textarea);

      this.$store.dispatch("showMessage", {
        message: "Copied Successfully!",
        messageType: "info",
      });
    },
    async downloadAttachment(fileType) {
      this.loadingDialog = !this.loadingDialog;
      const filesDownload = [];

      switch (fileType) {
        case "Download All":
          filesDownload.push(
            {
              fileName: `${this.companyName}_JD14`,
              fileStage: this.hrdcData.stagePriority.clientRevertJd14Form,
              azureStorage: true,
            },
            {
              fileName: `${this.companyName}_T3`,
              fileStage: this.hrdcData.stagePriority.clientRevertT3Form,
              azureStorage: true,
            },
            {
              fileName: `${this.formData.manualInvoiceToHrdc}`,
              fileStage: this.hrdcData.stagePriority.manualInvoiceToHrdc,
              azureStorage: true,
            },
            {
              fileName: this.agendaAtchName,
              fileStage: "null",
              azureStorage: false,
            }
          );

          break;
        case "Download JD14":
          filesDownload.push({
            fileName: `${this.companyName}_JD14`,
            fileStage: this.hrdcData.stagePriority.clientRevertJd14Form,
            azureStorage: true,
          });
          break;
        case "Download T3":
          filesDownload.push({
            fileName: `${this.companyName}_T3`,
            fileStage: this.hrdcData.stagePriority.clientRevertT3Form,
            azureStorage: true,
          });
          break;
        case "Download Agenda":
          filesDownload.push({
            fileName: this.agendaAtchName,
            fileStage: "null",
            azureStorage: false,
          });
          break;
        case "Download HRDC Invoice":
          filesDownload.push({
            fileName: `${this.formData.manualInvoiceToHrdc}`,
            fileStage: this.hrdcData.stagePriority.manualInvoiceToHrdc,
            azureStorage: true,
          });
          break;
      }

      for (const file of filesDownload) {
        await this.$axios
          .get(this.apiDownloadAttachment.url, {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.state.auth.data.token}`,
            },
            params: {
              applicationUuid: this.$route.query._ausk,
              filesDownload: file.fileStage,
              azureStorage: file.azureStorage,
              fileName: file.fileName,
            },
          })
          .then((response) => {
            if (response.data.size > 10) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `${file.fileName.trim()}.pdf`);
              document.body.appendChild(link);
              link.click();
            } else {
              this.$store.dispatch("showMessage", {
                message: `${file.fileName} is not ready yet`,
                messageType: "info",
              });
            }
          })
          .catch((error) => {
            if ((error.response.status == 404) | true) {
              this.$store.dispatch("showMessage", {
                message:
                  "Failed to Preview/Download attachment, kindly contact administrator",
                messageType: "error",
                timeout: 2000,
              });
            }
          });
      }

      this.loadingDialog = !this.loadingDialog;
    },
    redirectBack() {
      let route = "HrdcTaxSummitApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state[this.service.key].data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    async submit() {
      this.loadingDialog = !this.loadingDialog;
      let data = {
        applicationUuid: this.formData.applicationNo,
        claimId: this.formData.claimId,
        claimSubmitDate: this.formData.claimSubmitDate,
        referById: this.auth.uuid,
        editMode: this.editMode,
      };

      this.apiSubmit.setParams(data);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.loadingDialog = !this.loadingDialog;
          if (!response.status) {
            this.showErrorMessage(response);
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });
            this.$router.push({
              name: "HrdcTaxSummitApplication",
              query: {
                viewId: this.$store.state[this.service.key].data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>
